<template>
  <div>
    <b-card-group deck>
      <b-card
          header="Thông tin cài đặt"
          header-tag="header"
      >
        <b-form >
          <div class="row">
            <div class="col-md-6">
              <b-form-group
                  id="campaign_title"
                  label-for="input-1"
                  label="Tên chiến dịch (*):"
              >
                <b-form-input
                    id="input-1"
                    v-model="form.title"
                    placeholder="Tên chiến dịch "
                    :class="[{ 'is-invalid': form.errors.has('title') }]"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6" >
              <b-form-group
                  id="campaign_title"
                  label-for=""
                  label="Thời gian săn thưởng:(*)"
              >
                <b-form-input
                    id="input-1"
                    required
                    v-model="form.time_to_get_bonus"
                    placeholder="48"
                    :class="[{ 'is-invalid': form.errors.has('time_to_get_bonus') }]"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <b-form-group
                  id="client_id"
                  label="Mô tả (nếu có):"

              >
                <b-form-textarea
                    v-model="form.description"
                    :class="[{ 'is-invalid': form.errors.has('description') }]"
                    placeholder="Nhập mô tả..."
                    rows="3"
                    max-rows="6"
                >

                </b-form-textarea>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="Banner in-app/ Thumb (600.315) (*):"  >
                <div style="display: flex" >
                  <div style="height: 30px; margin-right: 20px" v-show="form.banner.length >0">
                    <img
                        style="width: 38px; height: 38px"
                        :src="form.banner"
                        alt=""
                    />
                  </div>
                  <b-form-file
                      @change="onImageChange($event,'banner')"
                      :required="form.banner.length > 0?false:true"
                      placeholder="chọn Banner in-app"
                      accept="image/*"
                      type="file"
                  ></b-form-file>
                </div>
              </b-form-group>

              <b-form-group label="Banner Landing (1920 x 595)(*):"  >
                <div style="display: flex" >
                  <div style="height: 30px; margin-right: 20px" v-show="form.banner_landing_pc.length >0">
                    <img
                        style="width: 38px; height: 38px"
                        :src="form.banner_landing_pc"
                        alt=""
                    />
                  </div>
                  <b-form-file
                      @change="onImageChange($event,'banner_landing_pc')"
                      :required="form.banner_landing_pc.length > 0?false:true"
                      placeholder="chọn Banner Landing"
                      accept="image/*"
                      type="file"
                  ></b-form-file>
                </div>
              </b-form-group>

              <b-form-group label="Banner Landing (Mobile 375x540) (*):"  >
                <div style="display: flex" >
                  <div style="height: 30px; margin-right: 20px" v-show="form.banner_landing_mobile.length >0">
                    <img
                        style="width: 38px; height: 38px"
                        :src="form.banner_landing_mobile"
                        alt=""
                    />
                  </div>
                  <b-form-file
                      @change="onImageChange($event,'banner_landing_mobile')"
                      :required="form.banner_landing_mobile.length > 0?false:true"
                      placeholder="chọn Banner Landing"
                      accept="image/*"
                      type="file"
                  ></b-form-file>
                </div>
              </b-form-group>

              <b-form-group label="Thumb sharing  (600 x 315)(*):"  >
                <div style="display: flex" >
                  <div style="height: 30px; margin-right: 20px" v-show="form.banner_thumb_share.length >0">
                    <img
                        style="width: 38px; height: 38px"
                        :src="form.banner_thumb_share"
                        alt=""
                    />
                  </div>
                  <b-form-file
                      @change="onImageChange($event,'banner_thumb_share')"
                      :required="form.banner_thumb_share.length > 0?false:true"
                      placeholder="chọn Thumb sharing"
                      accept="image/*"
                      type="file"
                  ></b-form-file>
                </div>
              </b-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <b-form-group
                  id="client_id"
                  label="Kịch bản phát quà đặc biệt (*):"
                  label-for="input-1"

              >
                <b-form-select
                    v-model="form.special_script_id"
                    :class="[{ 'is-invalid': form.errors.has('special_script_id') }] " :options="scripts">
                </b-form-select>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group
                  id="client_id"
                  label="Kịch bản phát quà (*):"
                  label-for="input-1"

              >
                <b-form-select v-model="form.script_id" :class="[{ 'is-invalid': form.errors.has('script_id') }]" :options="scripts">
                </b-form-select>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <b-form-group
                  id="client_id"
                  label="Số lượt nhận quà tối đa trong ngày:"
                  label-for="input-22211"

              >
                <b-form-input
                    id="input-22211"
                    v-model="form.max_in_day"
                    type="number"
                    min="0"
                    placeholder="Số lượt nhận quà tối đa trong ngày"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group
                  id="client_id"
                  label="Số lượt nhận quà tối đa trong chương trình (*):"
                  label-for="input-2223"

              >
                <b-form-input
                    id="input-2223"
                    v-model="form.max_in_camp"
                    required
                    type="number"
                    min="0"
                    placeholder="Số lượt nhận quà tối đa trong chương trình"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
          <div class="row" >
            <div class="col-md-6">
              <b-form-group
                  id="input-group-4"
                  label="Thời gian bắt đầu(*):"
                  label-for="d3"

              >
                <date-picker
                    id="d3"
                    name="dp3"
                    v-model="form.valid_at"
                    type="datetime"
                    placeholder="Chọn thời gian"
                    :input-attr="{required: 'true',valid_at:''}"
                    format="YYYY-MM-DD HH:mm:ss"
                    value-type="format"
                ></date-picker>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group
                  id="input-group-4"
                  label="Thời gian kết thúc(*):"
                  label-for="d3"
              >
                <date-picker
                    id="d3"
                    name="dp3"
                    v-model="form.expired_at"
                    type="datetime"
                    placeholder="Chọn thời gian"
                    :input-attr="{required: 'true',expired_at:''}"
                    format="YYYY-MM-DD HH:mm:ss"
                    value-type="format"
                ></date-picker>
              </b-form-group>
            </div>
          </div>
          <div class="row" style="margin-bottom: 20px;">
            <div class="col-md-6">
              <b-form-group
                  id="client_id"
                  label="Thử thách (*):"
                  label-for=""

              >
                <b-form-select v-model="form.challenge_id" :class="[{ 'is-invalid': form.errors.has('challenge_id') }]" :options="challenges">
                </b-form-select>
              </b-form-group>
            </div>
            <div class="col-md-1">
              Kích hoạt
            </div>
            <div class="col-md-3">

              <b-form-checkbox
                  id="checkbox-1"
                  v-model="form.status"
                  value="1"
                  unchecked-value="0"
                  size="lg"
              >

              </b-form-checkbox>
            </div>
          </div>
          <hr>



          <div class="row">
            <div class="col-md-12">
              <b-button variant="success"  style="margin-right: 10px;" @click="onSubmit">Lưu</b-button>
              <router-link
                  :to="'/refer'"
              >
                <b-button variant="outline-primary">Hủy</b-button>
              </router-link>

            </div>
          </div>

        </b-form>
      </b-card>


    </b-card-group>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { Form } from 'vform';
import Common from "@/core/mixins/common";
import Multiselect from "vue-multiselect";
import Vue from "vue";

import RepositoryFactory from "@/core/repositories/repositoryFactory";
const WalletCampaignRepository = RepositoryFactory.get("comboVoucher");
const WalletRunRepository = RepositoryFactory.get("WalletRun");
const CampaignRepository = RepositoryFactory.get("Campaign");
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
Vue.component("multiselect", Multiselect);
export default {
  name: "FormComboVoucherCampaign",
  mixins: [Common],
  components: {DatePicker},
  data() {
    return {
      form: new Form({
        title: '',
        description: '',
        banner: '',
        valid_at: '',
        expired_at: '',
        max_in_day: '',
        max_in_camp: '',
        script_id: '',
        special_script_id: '',
        time_to_get_bonus: '',
        status: '',
        challenge_id: '',
        banner_landing_pc: '',
        banner_landing_mobile: '',
        banner_thumb_share: '',

      }),
      show: true,
      images: '',
      scripts: [],
      challenges: [],
    };

  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Cài đặt chiến dịch" },
    ]);
  },

  methods: {

    onImageChange(e,type) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      let fileSize = files[0].size / 1024 / 1024; // in MiB
      if (fileSize> 2){
        this.$notify({
          group: "foo",
          type: "error",
          title:"Chọn ảnh không vượt quá 2MB",
        });
        return false;
      }
      this.images = files[0];
      this.uploadMedia(type);
    },
    uploadMedia: async function (type) {
      this.$bus.$emit("show-loading", true);
      const formData = new FormData();
      formData.append("file", this.images);
      WalletRunRepository.uploadMedia(formData)
          .then((response) => {
            this.$bus.$emit("show-loading", false);
            if (1 === response.data.error_code) {
              this.$notify({
                group: "foo",
                type: "error",
                title: response.data.message,
              });
            } else {
              this.form[type] = response.data.data.link;
              /*if (type == 'banner'){
                this.form.banner = response.data.data.link;
              }
              if (type== 'banner_landing_pc'){
                this.form.banner_landing_pc = response.data.data.link;
              }*/

            }
          })
          .catch((response) => {
            this.$bus.$emit("show-loading", false);
            this.$notify({
              group: "foo",
              type: "error",
              title: response.data.message,
            });
          });
    },
    async getDataCreate() {
      let response = await WalletCampaignRepository.getListAllScripts();
      if (response.data.data){
        response.data.data.forEach(item=>{
          this.scripts.push(
              {
                value:item.id,
                text:item.event.name,
              }
          )
        })
      }
    },
    async getListChallenges() {
      let response = await CampaignRepository.getListAllChallenge();
      if (response.data.data){
        response.data.data.forEach(item=>{
          this.challenges.push(
              {
                value:item.id,
                text:item.title,
              }
          )
        })
      }
    },
    isEmpty(value){
          return (typeof value === "undefined" || value === null || value === '');
    },
    onSubmit () {
      this.$bus.$emit("show-loading", true);
      this.form.submit('post', '/refer/config')
          .then((response) => {
            this.$bus.$emit("show-loading", false);
            if (1 === response.data.error_code) {
              this.$notify({
                group: "foo",
                type: "error",
                title: response.data.message,
              });
            } else {
              this.$notify({
                group: "foo",
                type: "success",
                title: response.data.message,
              });
            }
          })
          .catch((response) => {
            this.$bus.$emit("show-loading", false);
            this.$notify({
              group: "foo",
              type: "error",
              title: response.data.message,
            });
          });
    },
    async loadReferInfo () {
      try {
        this.$bus.$emit("show-loading", true);
        let response = await WalletCampaignRepository.loadReferInfo();
        this.$bus.$emit("show-loading", false);
        if (response.data.error_code === 0) {
          this.form.fill(response.data.data)
        } else {
          this.$notify({
            group: "foo",
            type: "error",
            title: response.data.message,
          });
        }
      } catch (error) {
        this.$bus.$emit("show-loading", false);
        this.$notify({
          group: "foo",
          type: "error",
          title: error,
        });
      }
    },
  },
  created() {
    this.getDataCreate();
    this.getListChallenges();
    this.loadReferInfo();
  },
  watch: {
    'form.type'() {
    }
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

